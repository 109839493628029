<template>
  <div v-if="address">
    <div class="address-detail">
      <p>{{ address.contactName }}, {{ address.contactNumber }},</p>
      <p>{{ address.addressLine1 }}, {{ address.addressLine2 }},</p>
      <p>
        {{ address.city }}, {{ address.country }} -
        <span>{{ address.zipcode }}</span>
      </p>
      <p class="text-lowercase">
        {{ address.contactEmail }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddressDetail',
  props: {
    address: {
      type: Object,
      default: () => {},
    },
    cols: {
      type: String,
      default() {
        return '6'
      },
    },
  },
}
</script>
<style lang="scss">
@import 'src/@core/scss/base/bootstrap-extended/_variables.scss';
.address-detail {
  display: flex;
  color: $actions-text-grey;
  flex-direction: column;
  text-transform: capitalize;
  p {
    margin-bottom: 0.5rem;
  }
}
</style>
