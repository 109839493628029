<template>
  <b-card>
    <b-row>
      <!-- Billing Address List -->
      <b-col cols="12">
        <address-list
          :address="billingAddress"
          :address-type="ADDRESSES_TYPE.BILLING"
          :commons="commonData"
          :add-or-update-address="addOrUpdateAddress"
        />
        <hr class="my-2">
      </b-col>
      <!-- Shipping Address List -->
      <b-col cols="12">
        <address-list
          :address="shippingAddress"
          :address-type="ADDRESSES_TYPE.SHIPPING"
          :remove-address="removeAddress"
          :set-as-primary-address="setAsPrimaryAddress"
          :commons="commonData"
          :add-or-update-address="addOrUpdateAddress"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'
import AddressList from './multiple-address/AddressList.vue'
import AddressMixinVue from './multiple-address/AddressMixin.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    AddressList,
  },
  mixins: [AddressMixinVue],
  props: {
    commonData: {
      type: Object,
      default: () => ({}),
    },
    addresses: {
      type: Array,
      default: () => { [] },
    },
    getUserProfile: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    addresses: {
      handler(newVal) {
        this.getBillingAndShippingAddress(newVal)
      },
      deep: true,
    },
  },
  created() {
    this.getBillingAndShippingAddress(this.addresses)
  },
  methods: {
    removeAddress(address) {
      const addresses = this.addresses.filter(addr => addr._id !== address._id)
      this.updateAddress(addresses, 'Address deleted successfully')
    },
  },
}
</script>
