<template>
  <b-card
    no-body
    class="mb-0"
  >
    <b-row>
      <b-col
        cols="12"
        class="p-2 px-3 d-flex justify-content-between align-items-center"
      >
        <div class="font-weight-bold h4">
          Manage team members
        </div>
        <b-button
          v-if="!isSalesRep"
          v-b-modal.stop="'invite-additional-user'"
          variant="success"
        >
          <feather-icon icon="UserPlusIcon" /> Add Teammate
        </b-button>
      </b-col>
      <!-- modal invite additional user -->
      <b-modal
        id="invite-additional-user"
        centered
        :title="`${additionalUserId ? 'Update' : 'Invite'} Additional User`"
        hide-footer
        @hidden="clearFormData"
      >
        <validation-observer
          #default="{ invalid }"
        >
          <b-row>
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="First Name"
                vid="firstName"
                rules="required"
              >
                <b-form-input
                  v-model="firstName"
                  name="firstName"
                  placeholder="Enter First Name"
                />
                <small class="text-danger"> {{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                vid="lastName"
                rules="required"
              >
                <b-form-input
                  v-model="lastName"
                  name="lastName"
                  placeholder="Enter Last Name"
                />
                <small class="text-danger"> {{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  v-model="email"
                  class="mt-1"
                  name="email"
                  :disabled="!!additionalUserId"
                  placeholder="Enter Email"
                />
                <small class="text-danger"> {{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <b-dropdown
                no-caret
                variant="outline-primary"
                class="text-capitalize text-left role-dropdown"
                block
                menu-class="w-100"
                :text="additionalUserRole || 'Select role'"
              >
                <template #button-content>
                  <div class="d-flex justify-content-between text-capitalize">
                    <span> {{ USER_ROLES_LABEL[additionalUserRole] || additionalUserRole || 'Select role' }} </span>
                    <feather-icon
                      icon="ChevronDownIcon"
                    />
                  </div>
                </template>
                <b-dropdown-item
                  v-for="role in teamMatesRoles"
                  :key="role"
                  class="w-100"
                  :active="additionalUserRole === role"
                  @click="additionalUserRole = role"
                >
                  <span class="text-capitalize"> {{ USER_ROLES_LABEL[role] || role }} </span>
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
          <b-row
            v-if="isEntityAdmin && additionalUserRole === USER_ROLES.SALESREPRESENTATIVE"
            class="mt-1"
          >
            <b-col>
              <b-dropdown
                no-caret
                variant="outline-primary"
                class="text-capitalize text-left role-dropdown"
                block
                menu-class="w-100"
              >
                <template #button-content>
                  <div class="d-flex justify-content-between text-capitalize">
                    <span> {{ reportingTo && reportingTo.firstName || 'Select reporting manager' }} </span>
                    <feather-icon
                      icon="ChevronDownIcon"
                    />
                  </div>
                </template>
                <b-dropdown-text v-if="!salesManagers.length">
                  <div

                    class="py-2 text-center"
                  >
                    Nothing was found.
                  </div>
                </b-dropdown-text>
                <template v-else>
                  <b-dropdown-item
                    v-for="reportingUser in salesManagers"
                    :key="reportingUser.userId"
                    class="w-100"
                    :active="reportingTo && reportingTo.userId === reportingUser.userId"
                    @click="reportingTo = reportingUser"
                  >
                    <span class="text-capitalize"> {{ reportingUser.firstName || '-' }} </span>
                  </b-dropdown-item>
                </template>

              </b-dropdown>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-end my-1">
            <b-button
              variant="outline-primary"
              @click="$bvModal.hide('invite-additional-user')"
            >
              Cancel
            </b-button>
            <b-button
              v-if="additionalUserId"
              variant="primary ml-1"
              :disabled="invalid || !additionalUserRole || isSaving"
              @click="updateAdditionalUser"
            >
              {{ isSaving ? 'Updating': 'Update' }}
            </b-button>
            <b-button
              v-else
              variant="primary ml-1"
              :disabled="invalid || !additionalUserRole || isSaving"
              @click="sendInviteToAdditionalUser"
            >
              {{ isSaving ? 'Creating': 'Create' }}
            </b-button>
          </div>
        </validation-observer>
      </b-modal>
    </b-row>
    <template v-if="isLoadingTeammates">
      <div class="text-center p-4">
        <b-spinner />
      </div>
    </template>
    <b-table
      v-else
      ref="additionalUserListTable"
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="teamMatesList"
      responsive
      :fields="tableColumns"
      primary-key="_id"
      show-empty
      empty-text="No Users found"
    >
      <!-- Column: First Name -->
      <template #cell(firstName)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.firstName }}
          </span>
        </b-media>
      </template>
      <!-- Column: Last Name -->
      <template #cell(lastName)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.lastName }}
          </span>
        </b-media>
      </template>
      <!-- Column: Email -->
      <template #cell(email)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.email }}
          </span>
        </b-media>
      </template>
      <template #cell(role)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap text-capitalize">
            {{ USER_ROLES_LABEL[data.item.role] || data.item.role || data.item.entityType }}
          </span>
        </b-media>
      </template>
      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${parseStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ data.item.status }}
        </b-badge>
      </template>
      <template #cell(_id)="data">
        <div class="d-flex flex-nowrap">
          <span
            v-b-tooltip.hover="'Edit'"
            class="cursor-pointer mr-2"
            @click="showUpdateAdditionalUser(data.item)"
          >
            <feather-icon
              icon="EditIcon"
              size="18"
              color="black"
            />
          </span>
          <span
            v-b-tooltip.hover="'Delete'"
            class="cursor-pointer"
            @click="showDeleteModal(data.item)"
          >
            <feather-icon
              icon="TrashIcon"
              size="18"
              color="red"
            />
          </span>
        </div>
      </template>
    </b-table>
    <b-modal
      id="delete_teammate_modal"
      size="sm"
      modal-class="modal-warning"
      title="Delete Teammate"
      centered
      ok-variant="warning"
      ok-title="Yes"
      @ok="deleteAdditionalUser"
    >
      <b-card-text>Are you sure you want to delete {{ selectedAdditionalUserForDelete.firstName }}?</b-card-text>
    </b-modal>
    <div
      v-if="hasPagination"
      class="mx-2 mb-2 mt-auto"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalAdditionalUsers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import analytics from '@/@core/utils/analytics'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import constants, { USER_ROLES, USER_ROLES_LABEL } from '@/constants'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import {
BBadge,
BButton,
BCard,
BCardText,
BCol,
BDropdown,
BDropdownItem,
BFormInput,
BMedia,
BModal,
BPagination,
BRow,
BTable,
VBModal,
VBTooltip,
BSpinner,
BDropdownText,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FETCH_ALL_SCOPES } from '@/store/modules/auth.module'
import { mapState } from 'vuex'
import UserRoleMixinVue from '../UserRoleMixin.vue'

const { ADDITIONAL_USER_STATUS, ADMIN_USER_STATUS } = c

export default {
  name: 'AccountSettingAdditionalUsers',
  components: {
    BCard,
    BButton,
    BFormInput,
    BMedia,
    BTable,
    BBadge,
    BRow,
    BCol,
    BPagination,
    BModal,
    FeatherIcon,
    BDropdown,
    BDropdownItem,
    ValidationObserver,
    ValidationProvider,
    BCardText,
    BSpinner,
    BDropdownText,
  },
  directives: {
    'v-b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  mixins: [UserRoleMixinVue],
  props: {
    additionalUsers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    let tableColumns = []
    tableColumns = [
      {
        key: 'firstName',
        label: 'First Name',
      },
      {
        key: 'lastName',
        label: 'Last Name',
      },
      {
        key: 'email',
        label: 'Email',
      },
      {
        key: 'role',
        label: 'Role',
      },
      {
        key: 'status',
        label: 'Status',
        sortable: true,
      },
      {
        key: '_id',
        label: 'Actions',
      },
    ]
    return {
      currentPage: 1,
      perPage: 10,
      tableColumns,
      firstName: '',
      email: '',
      lastName: '',
      additionalUserRole: '',
      isSaving: false,
      additionalUserId: '',
      selectedAdditionalUserForDelete: {},
      reportingTo: null,
      USER_ROLES,
      USER_ROLES_LABEL,
      teamMatesList: [],
      isLoadingTeammates: false,
    }
  },
  computed: {
    ...mapState({
      allScopes: state => state.auth.allScopes,
      salesManagers: state => state.auth.salesManagers,
    }),
    teamMatesRoles() {
      return this.$store.getters.teamMatesRoles
    },
    hasPagination() {
      return this.totalAdditionalUsers > 10
    },
    totalAdditionalUsers() {
      return this.teamMatesList.length
    },
  },
  created() {
    this.loadAllScopes()
  },
  methods: {
    async loadAllScopes() {
      this.isLoadingTeammates = true
      try {
        await this.$store.dispatch(FETCH_ALL_SCOPES)
        this.teamMatesList = this.allScopes
        this.isLoadingTeammates = false
      } catch (error) {
        apiToastWarning(error)
        this.isLoadingTeammates = false
      }
    },
    deleteAdditionalUser() {
      if (this.selectedAdditionalUserForDelete?._id) {
        this.$kpRequest({ ...this.$kpParams(this.$kpEndpoint.profile.additionalUser.delete, this.selectedAdditionalUserForDelete?._id) })
        .then(res => {
          apiToastSuccess(res.data.message)
          this.selectedAdditionalUserForDelete = {}
          this.$bvModal.hide('delete_teammate_modal')
          this.loadAllScopes()
         })
        .catch(err => {
          apiToastWarning(err)
        })
      }
    },
    showUpdateAdditionalUser(additionalUser) {
      this.firstName = additionalUser.firstName
      this.lastName = additionalUser.lastName
      this.email = additionalUser.email
      this.additionalUserRole = additionalUser?.role || ''
      this.additionalUserId = additionalUser._id
      this.$bvModal.show('invite-additional-user')
    },
    sendInviteToAdditionalUser() {
      this.isSaving = true
      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        role: this.additionalUserRole,
      }
      if (this.isEntityAdmin && this.additionalUserRole === USER_ROLES.SALESREPRESENTATIVE) {
        payload.reportingManagerId = this.reportingTo.userId
      }
      this.$kpRequest({ ...this.$kpEndpoint.profile.additionalUser.add, payload })
        .then(res => {
          apiToastSuccess(res.data.message)
          this.$bvModal.hide('invite-additional-user')
          analytics.track(constants.TRACKS.ACTIONS.INVITE_ADDITIONAL_USERS_PROFILE)
          this.loadAllScopes()
        })
        .catch(err => apiToastWarning(err))
        .finally(() => {
          this.isSaving = false
        })
    },
    updateAdditionalUser() {
      this.isSaving = true
      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        role: this.additionalUserRole,
        _id: this.additionalUserId,
      }
      this.$kpRequest({ ...this.$kpEndpoint.profile.additionalUser.update, payload })
        .then(res => {
          apiToastSuccess(res.data.message)
          this.$bvModal.hide('invite-additional-user')
          this.loadAllScopes()
        })
        .catch(err => apiToastWarning(err))
        .finally(() => {
          this.isSaving = false
        })
    },
    clearFormData() {
      this.firstName = ''
      this.lastName = ''
      this.email = ''
      this.additionalUserId = ''
      this.additionalUserRole = ''
      this.reportingTo = null
      this.isSaving = false
    },
    parseStatusVariant(status) {
      switch (status.toLowerCase()) {
        case ADDITIONAL_USER_STATUS.PENDING:
          return 'warning'
        case ADDITIONAL_USER_STATUS.ACCEPTED:
        case ADMIN_USER_STATUS.ACTIVE:
          return 'success'
        default:
          return 'danger'
      }
    },
    showDeleteModal(data) {
      this.selectedAdditionalUserForDelete = data
      this.$bvModal.show('delete_teammate_modal')
    },
  },
}
</script>
<style lang="scss">
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";
.role-dropdown {
  .btn-outline-primary {
    border: 1px solid $custom-input-border-color !important;
  }
}
</style>
