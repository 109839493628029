<template>
  <b-card>
    <validation-observer
      ref="accountSettingSocialForm"
      #default="{ invalid }"
    >
      <!-- form -->
      <b-form @submit.prevent="savePassword">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Old Password"
              label-for="account-old-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Old Password"
                vid="Old Password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Enter Old Password"
                    :state="errors.length > 0 ? false : null"
                    required
                    autocomplete="off"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="New Password"
            >
              <validation-provider
                #default="{ errors }"
                name="New Password"
                vid="New Password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="Enter New Password"
                    :state="errors.length > 0 ? false : null"
                    required
                    autocomplete="off"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Retype New Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Retype Password"
                vid="Retype Password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="Retype New Password"
                    :state="errors.length > 0 ? false : null"
                    required
                    autocomplete="off"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              :disabled="invalid || saving"
              @click.prevent="savePassword"
            >
              Save changes
              <feather-icon
                v-if="saving"
                icon="LoaderIcon"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import { PURGE_AUTH } from '@/store/modules/auth.module'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      saving: false,
      // validation
      required,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    resetForm() {
      this.passwordValueOld = ''
      this.newPasswordValue = ''
      this.RetypePassword = ''
    },
    savePassword() {
      if (this.newPasswordValue !== this.RetypePassword) {
        apiToastWarning('Retype password miss match!')
      } else {
        this.saving = true
        this.$kpRequest({
 ...this.$kpEndpoint.profile.changePassword,
payload: {
            oldPassword: this.passwordValueOld,
            password: this.newPasswordValue,
            password2: this.RetypePassword,
          },
})
          .then(res => {
            apiToastSuccess(res.data.message)
            this.$store.commit(PURGE_AUTH)
            this.$router.push({ name: 'auth-login' })
            analytics.track(constants.TRACKS.ACTIONS.SAVE_PROFILE_SETTINGS_PASSWORD)
          })
          .catch(err => {
            apiToastWarning(err)
          })
          .finally(() => {
            this.saving = false
          })
      }
    },
  },
}
</script>
