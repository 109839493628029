<template>
  <div>
    <b-row>
      <!-- Type -->
      <b-col
        md="6"
        class="mb-1"
      >
        <span>Collection Schedule</span>
        <b-button
          v-if="!isAppearCreateBtn"
          class="ml-1"
          variant="outline-primary"
          size="sm"
          @click="addItem"
        >
          ADD
        </b-button>
        <b-button
          v-else
          class="ml-1"
          variant="primary"
          size="sm"
          @click="saveCollection"
        >
          CREATE
        </b-button>
      </b-col>
    </b-row>
    <b-row
      v-for="(item, index) in scheduledCollections"
      :key="index"
    >
      <!-- Season -->
      <b-col md="6">
        <b-form-group label="Season">
          <v-select
            v-model="item.seasonType"
            :class="{'mt-8-px': rawScheduledCollections[index]}"
            :options="Object.values(SCHEDULED_COLLECTION_TYPE)"
            :reduce="(type) => type.LABEL"
            label="TEXT"
          />
        </b-form-group>
      </b-col>
      <!-- Collection Launch Month -->
      <b-col md="6">
        <b-form-group>
          <template #label>
            <div class="w-100 d-flex align-items-center">
              <span>Collection Launch Month</span>
              <b-button
                v-if="item._id"
                class="ml-auto"
                variant="outline-danger"
                size="sm"
                @click.stop="removeCollection(item)"
              >
                DELETE
              </b-button>
            </div>
          </template>
          <v-select
            v-model="item.month"
            :options="Object.values(MONTHS)"
            :reduce="(month) => month.LABEL"
            label="TEXT"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
 BButton, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import { apiToastErrorV2, apiToastSuccess } from '@/@core/utils/toast'
import { CREATE_SCHEDULE, DELETE_SCHEDULE, GET_SCHEDULE } from '@/store/modules/brand.module'

const { SCHEDULED_COLLECTION_TYPE, MONTHS } = constants

const buildDefaultItem = () => ({
           seasonType: '',
           month: '',
        })

export default {
  name: 'CollectionSchedule',
  components: {
    vSelect,
    BButton,
    BFormGroup,
    BRow,
    BCol,
  },
  data() {
    return {
      selectedCollectionType: '',
      scheduledCollections: [],
      rawScheduledCollections: [],
      SCHEDULED_COLLECTION_TYPE,
      MONTHS,
    }
  },
  computed: {
    isAppearCreateBtn() {
      let isHideBtn = false
      if (this.rawScheduledCollections.length !== this.scheduledCollections.length) {
            isHideBtn = true
      }
      return isHideBtn
    },
  },
  created() {
    this.loadScheduledCollections()
  },
  methods: {
    addItem() {
        this.scheduledCollections.push(buildDefaultItem())
    },
    loadScheduledCollections() {
      this.$store.dispatch(GET_SCHEDULE)
        .then(res => {
          const schedules = res.data?.data?.schedules
          if (!schedules?.length) {
            this.scheduledCollections = [buildDefaultItem()]
            this.rawScheduledCollections = []
          } else {
            this.rawScheduledCollections = [...schedules]
            this.scheduledCollections = schedules
          }
        })
        .catch(err => {
          apiToastErrorV2(err)
        })
    },
    saveCollection() {
        const collection = this.scheduledCollections[this.scheduledCollections.length - 1]
        if (!collection.seasonType) {
          apiToastErrorV2('Please select a season.')
          return null
        }
        if (!collection.month) {
          apiToastErrorV2('Please select collection launch month.')
          return null
        }
        const payload = {
            seasonType: collection.seasonType,
            month: collection.month,
        }
        this.$store.dispatch(CREATE_SCHEDULE, payload)
        .then(res => {
          apiToastSuccess(res.data.message)
          this.loadScheduledCollections()
        })
        .catch(err => {
          apiToastErrorV2(err)
        })
    },
    removeCollection(schedule = {}) {
      this.$store.dispatch(DELETE_SCHEDULE, schedule._id)
        .then(res => {
          apiToastSuccess(res.data.message || 'Collection deleted successfully!')
          this.loadScheduledCollections()
        })
        .catch(err => {
          apiToastErrorV2(err)
        })
    },
  },
}
</script>
