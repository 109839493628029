<template>
  <b-card>
    <!-- logo & banners -->
    <b-row>
      <!-- upload logo -->
      <b-col md="6">
        <b-media no-body>
          <b-media-aside>
            <b-link v-if="logoImg">
              <b-img
                rounded
                thumbnail
                :src="logoImg"
              />
            </b-link>
            <b-link v-else>
              <div class="rounded img-thumbnail">
                <feather-icon
                  v-if="!logoImg"
                  icon="UploadCloudIcon"
                  size="30"
                  class="position-absolute"
                  @click="$refs.refInputEl.$el.click()"
                />
              </div>
            </b-link>
            <!--/ avatar -->
          </b-media-aside>

          <b-media-body class="mt-75 ml-75">
            <!-- upload button -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              @click="$refs.refInputEl.$el.click()"
            >
              Upload logo
            </b-button>
            <b-form-file
              ref="refInputEl"
              v-model="uncroppedLogoFile"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
            />
            <!--/ upload button -->

            <!-- reset -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              size="sm"
              class="mb-75 mr-75"
              @click.stop="
                logoFile = null;
                logoImg = null;
                resetLogo();
              "
            >
              Reset logo
            </b-button>
            <!--/ reset -->
            <b-card-text>Upload your logo here. Allowed JPG, GIF or PNG. Max size of
              5MB</b-card-text>
          </b-media-body>
        </b-media>
      </b-col>
      <!--/ upload logo -->
      <!-- upload banners -->
      <b-col md="6">
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <template v-if="bannerImgs.length">
                <b-carousel
                  id="carousel-interval"
                  controls
                  indicators
                  fade
                  :interval="3000"
                  class="carousel"
                >
                  <b-carousel-slide
                    v-for="img in bannerImgs"
                    :key="img"
                  >
                    <template #img>
                      <b-img
                        thumbnail
                        rounded
                        :src="img"
                        class="thumbnail-image"
                      />
                    </template>
                  </b-carousel-slide>
                </b-carousel>
              </template>
              <template v-else>
                <b-img
                  rounded
                  thumbnail
                />
                <feather-icon
                  icon="UploadCloudIcon"
                  size="30"
                  class="position-absolute"
                  @click="$bvModal.show('modal-select')"
                />
              </template>
            </b-link>
            <!--/ avatar -->
          </b-media-aside>

          <b-media-body class="mt-75 ml-75">
            <!-- upload button -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-select
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
            >
              Upload banners
            </b-button>
            <!--/ upload button -->

            <!-- reset -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              size="sm"
              class="mb-75 mr-75"
              @click.stop="
                bannerImgs = [];
                resetBanners();
              "
            >
              Reset banners
            </b-button>
            <!--/ reset -->
            <b-card-text>Upload up to 3 banners here. Allowed JPG, GIF or PNG. Max size of
              5MB each.</b-card-text>
          </b-media-body>
        </b-media>
      </b-col>
      <!--/ upload banners -->
    </b-row>
    <!-- form -->
    <b-form
      class="mt-2"
      @submit.prevent="saveGeneralSettings"
    >
      <b-row>
        <!-- entity name -->
        <b-col sm="6">
          <b-form-group
            :label="entityName"
            label-for="account-entityName"
          >
            <b-form-input
              v-model="generalLocal.entityName"
              name="entityName"
              :placeholder="`Enter ${entityName}`"
            />
          </b-form-group>
        </b-col>
        <!-- position -->
        <b-col sm="6">
          <b-form-group
            label="Position"
            label-for="account-position"
          >
            <b-form-input
              :value="USER_ROLES_LABEL[generalLocal.role]"
              name="account-position"
              placeholder="Position"
              disabled
            />
          </b-form-group>
        </b-col>
        <!-- first name -->
        <b-col sm="6">
          <b-form-group
            label="First Name"
            label-for="account-firstname"
          >
            <b-form-input
              v-model="generalLocal.firstName"
              name="firstName"
              placeholder="Enter First Name"
            />
          </b-form-group>
        </b-col>
        <!-- last name -->
        <b-col sm="6">
          <b-form-group
            label="Last Name"
            label-for="account-lastname"
          >
            <b-form-input
              v-model="generalLocal.lastName"
              name="lastName"
              placeholder="Enter Last Name"
            />
          </b-form-group>
        </b-col>
        <!-- country -->
        <b-col sm="6">
          <b-form-group
            label="Country"
            label-for="account-country"
          >
            <v-select
              id="account-country"
              v-model="generalLocal.country"
              name="country"
              :options="commonData.COUNTRIES"
              placeholder="Enter Country"
            />
          </b-form-group>
        </b-col>
        <!-- phone -->
        <b-col sm="6">
          <b-form-group
            label="Phone"
            label-for="account-phone"
          >
            <vue-tel-input
              :value="generalLocal.phone ? fullPhoneNumber : ''"
              :input-options="inputOptions"
              class="form-control"
              mode="international"
              name="phone"
              @input="onInputPhoneNumber"
            />
            <small
              v-if="isNotValidPhoneNumber"
              class="text-danger"
            >Please enter the valid phone number</small>
          </b-form-group>
        </b-col>
        <b-col
          v-if="isRetailer"
          sm="6"
        >
          <b-form-group
            label="Store Type"
            label-for="store-type"
          >
            <v-select
              id="store-type"
              v-model="generalLocal.storeType"
              name="country"
              :options="Object.values(STORE_TYPE)"
              :reduce="(storeType) => storeType.LABEL"
              label="LABEL"
              class="text-capitalize"
              placeholder="Enter Store Type"
            />
          </b-form-group>
        </b-col>
        <!-- about -->
        <b-col sm="12">
          <b-form-group
            label="About"
            label-for="account-about"
          >
            <b-form-textarea
              v-model="generalLocal.storeDescription"
              name="storeDescription"
              placeholder="Enter About"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            :disabled="saving"
            @click="saveGeneralSettings"
          >
            Save changes
            <feather-icon
              v-if="saving"
              icon="LoaderIcon"
            />
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <b-modal
      id="modal-select"
      size="lg"
      modal-class="modal-primary"
      centered
      title="Upload Banner"
      hide-footer
      header-class="custom-modal-heading"
    >
      <banner-cropper
        :profile-general="generalLocal"
        @on-cancel="closePopup"
        @on-save="updateBanners"
      />
    </b-modal>
    <b-modal
      id="croplogophoto"
      ref="croplogophoto"
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      centered
      size="lg"
    >
      <div class="pb-1 mb-2 border-bottom">
        <span class="custom-modal-heading">Crop Logo </span>
        <feather-icon
          class="float-right close-x-icon"
          icon="XIcon"
          size="24"
          @click="closeCrop"
        />
      </div>
      <div class="cropper-section mb-1">
        <Cropper
          ref="logoCropper"
          class="cropper"
          :src="croppedImg"
          :stencil-props="{
            aspectRatio: 1/1
          }"
          image-restriction="fit-area"
        />
        <div class="text-center mt-1">
          <b-button
            class="mr-1 font-weight-bolder"
            variant="outline-info"
            @click="closeCrop"
          >
            Cancel
          </b-button>
          <b-button
            class="ml-1"
            variant="info"
            :disabled="isCropping"
            @click="crop"
          >
            {{ isCropping ? "Cropping...." : "Crop" }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BRow,
  BCol,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BModal,
  BCarousel,
  BCarouselSlide,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { apiToastWarning, apiToastSuccess } from '@/@core/utils/toast'
import { ucFirst } from '@/@core/utils/text'
import { getLinkWithoutQueryParams, dataURLtoFile, toBase64 } from '@/common-utils'
import analytics from '@/@core/utils/analytics'
import constants, { IMAGE_COMPRESSER, USER_ROLES_LABEL } from '@/constants'
import { FETCH_PROFILE } from '@/store/modules/auth.module'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import store from '@/store'
import Compress from 'compress.js'
import { Cropper } from 'vue-advanced-cropper'
import { UPLOAD_DOCUMENTS } from '@/store/modules/common.module'
import BannerCropper from './banner-cropper/BannerCropper.vue'

const { FILE_TYPES, STORE_TYPE } = c

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BCarousel,
    BCarouselSlide,
    BModal,
    BannerCropper,
    vSelect,
    Cropper,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
    commonData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const generalLocal = this.buildDefault()
    return {
      generalLocal,
      logoFile: null,
      uncroppedLogoFile: null,
      logoImg: generalLocal.logo,
      bannerImgs: generalLocal.banner,
      countries: [],
      saving: false,
      inputOptions: {
        id: 'contact-number',
        placeholder: 'Enter phone number',
      },
      croppedImg: null,
      isCropping: false,
      STORE_TYPE,
      fullPhoneNumber: '',
      isValidPhoneNumber: false,
      USER_ROLES_LABEL,
    }
  },
  computed: {
    entityName() {
      return `${ucFirst(this.$store.getters.entityType)} Name`
    },
    getDisplayLogoImage() {
      return this.tempLogoForUpload || this.logoImg
    },
    isRetailer() {
      return store.getters.isRetailer
    },
    isBrand() {
      return store.getters.isBrand
    },
    isNotValidPhoneNumber() {
      return this.generalLocal?.phone && !this.isValidPhoneNumber
    },
  },
  watch: {
    uncroppedLogoFile: {
      async handler(theFile) {
        if (!theFile) return
        this.croppedImg = await toBase64(theFile)
        this.$refs.croplogophoto.show()
      },
      immediate: true,
    },
  },
  created() {
    const generalData = this.buildDefault()
    this.fullPhoneNumber = `${generalData?.countryCode} ${generalData?.phone}`
  },
  emits: ['update-general'],
  methods: {
    onInputPhoneNumber(number, phoneObject) {
      this.isValidPhoneNumber = !!phoneObject?.valid
      this.generalLocal.phone = number && phoneObject.nationalNumber
      this.generalLocal.countryCode = `+${phoneObject.countryCallingCode}`
      this.fullPhoneNumber = `${this.generalLocal?.countryCode} ${this.generalLocal?.phone}`
    },
    updateBanners() {
      this.bannerImgs = this.generalLocal.banner
      this.saveGeneralSettings(null, false, false)
      this.$bvModal.hide('modal-select')
    },
    closePopup() {
      this.$bvModal.hide('modal-select')
    },
    closeCrop() {
      this.uncroppedLogoFile = null
      this.$refs.croplogophoto.hide()
    },
    async crop() {
      this.isCropping = true
      const { canvas } = this.$refs.logoCropper.getResult()
      const logoFile = canvas.toDataURL()
      let file = dataURLtoFile(logoFile, this.uncroppedLogoFile.name)
      if (file.size / 1024 > 1024) {
        const compress = new Compress()
        const data = await compress.compress([file], IMAGE_COMPRESSER)
        file = dataURLtoFile(data[0].prefix + data[0].data, this.uncroppedLogoFile.name)
      }
        this.logoImg = logoFile
        this.logoFile = file
        this.$refs.croplogophoto.hide()
        this.isCropping = false
        this.uncroppedLogoFile = null
    },
    buildDefault() {
      return JSON.parse(JSON.stringify(this.generalData))
    },
    resetForm() {
      this.generalLocal = this.buildDefault()
    },
    resetLogo() {
      this.saveGeneralSettings(null, true)
    },
    resetBanners() {
      this.saveGeneralSettings(null, false, true)
    },
    async saveGeneralSettings(event, resetLogo, resetBanner) {
      resetLogo = resetLogo || false
      resetBanner = resetBanner || false
      this.saving = true
      if (!resetLogo) await this.uploadLogo()
      const payload = this.generalLocal
      // reset logo
      if (resetLogo) {
        payload.logo = ''
      } else if (!(this.logoFile instanceof File) || !this.logoFile) {
        delete payload.logo
      }
      // reset banner
      if (resetBanner) {
        payload.banner = []
      }
      // Store type is only for retailer, deleteing in payload
      if (this.isBrand) {
        delete payload.storeType
      }
      // Removing Unwnated Queries in banner s3Links from payload
      payload.banner = this.generalLocal.banner.map(img => getLinkWithoutQueryParams(img))
      await this.$kpRequest({ ...this.$kpEndpoint.profile.general, payload })
        .then(res => {
          this.$emit('update-general', payload)
          apiToastSuccess(res.data.message)
          analytics.track(constants.TRACKS.ACTIONS.SAVE_PROFILE_SETTINGS_GENERAL)
          this.$store.dispatch(FETCH_PROFILE)
        })
        .catch(err => {
          apiToastWarning(err)
        })
      this.saving = false
    },
    async uploadLogo() {
      if (this.logoFile instanceof File) {
        this.generalLocal.logo = await this.$store.dispatch(UPLOAD_DOCUMENTS, { fileType: FILE_TYPES.LOGO, file: this.logoFile })
        this.logoImg = this.generalLocal.logo
        apiToastSuccess('Your file(s) are uploaded')
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import 'src/@core/scss/base/components/_variables.scss';
.media-aside {
  .img-thumbnail {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  .feather-upload-cloud {
    left: 40px;
    top: 25px;
  }
}
.carousel {
  width: 80px;
  height: 80px;
  background: $nav-component-border-color;
}
.thumbnail-image {
  object-fit: contain;
  width: 80px;
  height: 80px;
}
</style>
