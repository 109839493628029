<script>
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import { UPDATE_USER, UPDATE_USER_BY_ID } from '@/store/modules/auth.module'
import analytics from '@/@core/utils/analytics'
import { formatObject } from '@/@core/utils/utils'
import constants from '@/constants'

const { ADDRESSES_TYPE } = c

export default {
  data() {
    return {
      billingAddress: [],
      shippingAddress: [],
      ADDRESSES_TYPE,
      userId: null,
      selectAddressModalId: '',
    }
  },
  computed: {
    isCheckout() {
      return this.$route.name === 'checkout'
    },
  },
  methods: {
    getBillingAndShippingAddress(addresses) {
      addresses.forEach(add => {
        add.isEditEnabled = add.addressType === ADDRESSES_TYPE.BILLING
        add.isSaving = false
      })
      this.billingAddress = addresses.filter(item => item.addressType === ADDRESSES_TYPE.BILLING)
      this.shippingAddress = addresses.filter(item => item.addressType === ADDRESSES_TYPE.SHIPPING)
    },
    addOrUpdateAddress(address) {
      address.isSaving = true
      const addresses = JSON.parse(JSON.stringify(this.addresses))
      const index = addresses.findIndex(add => add._id === address._id)
      if (index !== -1) {
        addresses[index] = address
      }
      else {
        addresses.push(address)
      }
      this.updateAddress(addresses, 'Address updated successfully')
      const payload = {
        addresses,
      }
      if (this.isCheckout) {
        analytics.track(constants.TRACKS.ACTIONS.RETAILER_ADDS_NEW_ADDRESS_IN_CART)
      }
      else if (address._id) {
          analytics.track(
            constants.TRACKS.ACTIONS.CREATE_ADDRESS_PROFILE,
            formatObject(payload),
          )
        }
        else {
      analytics.track(
            constants.TRACKS.ACTIONS.UPDATE_ADDRESS_PROFILE,
            formatObject(payload),
          )
        }
    },
    setAsPrimaryAddress(addressId) {
        const addresses = JSON.parse(JSON.stringify(this.addresses))
        addresses.forEach(add => {
            add.isPrimary = false
            if (add._id === addressId || add.addressType === ADDRESSES_TYPE.BILLING) {
                add.isPrimary = true
            }
        })
        this.updateAddress(addresses, 'Primary address updated successfully')
    },
    updateAddress(addresses, successMessage = '') {
      const payload = {
        addresses,
      }
      let updateRequest = null
      if (this.userId) {
        updateRequest = this.$store.dispatch(UPDATE_USER_BY_ID, { userId: this.userId, payload })
      } else {
        updateRequest = this.$store.dispatch(UPDATE_USER, payload)
      }

      updateRequest.then(() => {
        if (this.isCheckout) {
          this.$bvModal.hide('modal-select')
          this.$bvModal.hide('modal-add-shipping')
          this.$bvModal.hide('modal-add-billing')
        }
        this.$bvModal.hide(this.selectAddressModalId)
        apiToastSuccess(successMessage)
        if (!this.userId) {
          this.getUserProfile()
        } else {
          this.addresses = addresses
          this.loadShippingAddress()
        }
      })
      .catch(err => {
        apiToastWarning(err)
      })
    },
  },
}
</script>
