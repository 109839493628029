<template>
  <b-tabs
    v-if="isLoaded && isCommonDataLoaded"
    v-model="tabIndex"
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span
          id="intro_general"
          class="font-weight-bolder"
          @click="() => trackPageView('ACCOUNT_SETTING_GENERAL')"
        >General</span>
      </template>

      <account-setting-general
        v-if="profile.general"
        :general-data="profile.general"
        :common-data="countryData"
        @update-general="updateGeneral"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span
          class="font-weight-bolder"
          @click="() => trackPageView('ACCOUNT_SETTING_PASSWORD')"
        >Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- segmentation tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="DatabaseIcon"
          size="18"
          class="mr-50"
        />
        <span
          id="intro_segmentation"
          class="font-weight-bolder"
          @click="() => trackPageView('ACCOUNT_SETTING_SEGMENTATION')"
        >Segmentation</span>
      </template>

      <account-setting-segmentation
        v-if="profile.segmentation && Object.values(commons).length"
        :segmentation-data="profile.segmentation"
        :departments="commons.DEPARTMENTS"
        :countries="countryData.COUNTRIES"
        :kingpin-presence-countries="commons.KINGPIN_PRESENCE_COUNTRIES"
        @update-segmentation="updateSegmentation"
      />
    </b-tab>

    <!-- documents tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="FileTextIcon"
          size="18"
          class="mr-50"
        />
        <span
          id="into_documents"
          class="font-weight-bolder"
          @click="() => trackPageView('ACCOUNT_SETTING_DOCUMENT')"
        >Commercial Information</span>
      </template>

      <account-setting-document
        v-if="profile.documents"
        :document-data="profile.documents"
        @update-document="updateDocument"
      />
    </b-tab>

    <!-- address -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="HomeIcon"
          size="18"
          class="mr-50"
        />
        <span
          id="intro_addresses"
          class="font-weight-bolder"
          @click="() => trackPageView('ACCOUNT_SETTING_ADDRESS')"
        >Addresses</span>
      </template>
      <!-- addresses -->
      <account-setting-address
        :addresses="addresses"
        :get-user-profile="getUserProfile"
        :common-data="countryData"
      />
    </b-tab>

    <!-- social links -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span
          class="font-weight-bolder"
          @click="() => trackPageView('ACCOUNT_SETTING_SOCIAL')"
        >Social</span>
      </template>

      <account-setting-social
        v-if="profile.social"
        :social-data="profile.social"
        @update-social="updateSocial"
      />
    </b-tab>

    <!-- users tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UsersIcon"
          size="18"
          class="mr-50"
        />
        <span
          class="font-weight-bolder"
          @click="() => trackPageView('SAVE_PROFILE_SETTINGS_ADDITIONAL_USERS')"
        >
          Teammates</span>
      </template>

      <account-setting-additional-users
        :additional-users="additionalUsers"
        :get-user-profile="getUserProfile"
      />
    </b-tab>
    <!--/ users tab -->

    <!-- Preview -->
    <template
      v-if="isBrand"
      #tabs-end
    >
      <li
        role="presentation"
        class="nav-item"
      >
        <b-link
          class="nav-link"
          :to="{ name: 'brand/detail', params: { brandId: profile._id } }"
        >
          <feather-icon
            icon="EyeIcon"
            size="18"
            class="mr-50"
          />
          <span
            id="intro_preview"
            class="font-weight-bolder"
          >Preview</span>
        </b-link>
      </li>
    </template>
    <tool-kit :toolkit-page="TOOLKIT_PAGE.PROFILE" />
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BLink } from 'bootstrap-vue'
import { getProperProfileData } from '@/common-utils'
import analytics from '@/@core/utils/analytics'
import constants, { TOOLKIT_PAGE } from '@/constants'
import { FETCH_PROFILE } from '@/store/modules/auth.module'
import { GET_COMMON_AUTH, GET_COUNTRIES } from '@/store/modules/common.module'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingDocument from './AccountSettingDocument.vue'
import AccountSettingAddress from './AccountSettingAddress.vue'
import AccountSettingSegmentation from './AccountSettingSegmentation.vue'
import AccountSettingAdditionalUsers from './AccountSettingAdditionalUsers.vue'
import UserRoleMixinVue from '../UserRoleMixin.vue'

export default {
  components: {
    BTabs,
    BTab,
    BLink,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingSocial,
    AccountSettingDocument,
    AccountSettingAddress,
    AccountSettingSegmentation,
    AccountSettingAdditionalUsers,
},
  mixins: [UserRoleMixinVue],
  data() {
    return {
      isLoaded: false,
      profile: {},
      commons: {},
      getProperProfileData,
      tabIndex: 0,
      TOOLKIT_PAGE,
      isCommonDataLoaded: false,
      addresses: [],
      additionalUsers: [],
    }
  },
  mounted() {
    this.getCommonAuthData()
    this.getCommonData()
    this.getUserProfile()
  },
  created() {
    analytics.track(constants.TRACKS.PAGES.ACCOUNT_SETTING_GENERAL)
  },
  methods: {
    trackPageView(page) {
      analytics.track(constants.TRACKS.PAGES[page])
    },
    updateGeneral(generalData) {
      this.profile.general = generalData
    },
    updateSocial(socialData) {
      this.profile.social = socialData
    },
    updateDocument(documentData) {
      this.profile.documents = documentData
    },
    updateSegmentation(segmentationData) {
      this.profile.segmentation = segmentationData
    },
    async getCommonAuthData() {
      await this.$store.dispatch(GET_COMMON_AUTH).then(res => {
        this.commons = res.data.data
      })
    },
    getCommonData() {
      this.$store.dispatch(GET_COUNTRIES).then(res => {
        this.countryData = res.data.data
        this.isCommonDataLoaded = true
      })
    },
    getUserProfile() {
      this.$store.dispatch(FETCH_PROFILE).then(res => {
      const data = res
      this.addresses = data?.addresses || []
      this.additionalUsers = data?.additionalUsers || []
      this.profile = getProperProfileData(data)
      this.isLoaded = true
      })
      if (this.$route.params.tabIndex) {
        setTimeout(() => {
          this.tabIndex = this.$route.params.tabIndex
        }, 500)
      }
    },
  },
}
</script>

<style>
.vue-tel-input {
  display: flex !important;
}
</style>
