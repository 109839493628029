<template>
  <b-card>
    <validation-observer
      ref="accountSettingSocialForm"
      #default="{ invalid }"
    >
      <b-form @submit.prevent="saveForm">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <feather-icon
                icon="LinkIcon"
                size="18"
              />
              <h4 class="mb-0 ml-75">
                Social Links
              </h4>
            </div>
          </b-col>

          <!-- website -->
          <b-col md="6">
            <b-form-group
              label-for="account-websiteLink"
              label="Website"
            >
              <validation-provider
                #default="{ errors }"
                name="websiteLink"
                vid="websiteLink"
                rules="required"
              >
                <b-form-input
                  id="account-websiteLink"
                  v-model="socialLocal.websiteLink"
                  name="account-websiteLink"
                  placeholder="Add website link"
                  :state="errors.length > 0 ? false : null"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ website -->

          <!-- twitter -->
          <b-col md="6">
            <b-form-group
              label-for="account-twitter"
              label="Twitter"
            >
              <b-form-input
                id="account-twitter"
                v-model="socialLocal.twitterLink"
                placeholder="Add twitter link"
              />
            </b-form-group>
          </b-col>
          <!--/ twitter -->

          <!-- facebook -->
          <b-col md="6">
            <b-form-group
              label-for="account-facebook"
              label="Facebook"
            >
              <b-form-input
                id="account-facebook"
                v-model="socialLocal.facebookLink"
                placeholder="Add facebook link"
              />
            </b-form-group>
          </b-col>
          <!--/ facebook -->

          <!-- instagram -->
          <b-col md="6">
            <b-form-group
              label="Instagram"
              label-for="account-instagram"
            >
              <b-form-input
                id="account-instagram"
                v-model="socialLocal.instagramLink"
                placeholder="Add instagram link"
              />
            </b-form-group>
          </b-col>
          <!--/ instagram -->

          <!-- linkedin -->
          <b-col md="6">
            <b-form-group
              label-for="account-linkedin"
              label="LinkedIn"
            >
              <b-form-input
                id="account-linkedin"
                v-model="socialLocal.linkedinLink"
                placeholder="Add linkedin link"
              />
            </b-form-group>
          </b-col>
          <!-- linkedin -->

          <b-col cols="12">
            <hr class="my-2">
          </b-col>
          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              :disabled="invalid || saving"
              @click.prevent="saveForm"
            >
              Save changes
              <feather-icon
                v-if="saving"
                icon="LoaderIcon"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              class="mt-1 ml-25"
              variant="outline-secondary"
              @click.prevent="resetForm"
            >
              Cancel
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { apiToastWarning, apiToastSuccess } from '@/@core/utils/toast'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    socialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      socialLocal: this.buildDefault(),
      saving: false,
    }
  },
  emits: ['update-social'],
  methods: {
    buildDefault() {
      let socialLocal = JSON.parse(JSON.stringify(this.socialData))
      if (!socialLocal) {
        socialLocal = {
          websiteLink: '',
          instagramLink: '',
          facebookLink: '',
          linkedinLink: '',
          twitterLink: '',
          websiteLinkError: false,
        }
      }
      return socialLocal
    },
    resetForm() {
      this.socialLocal = this.buildDefault()
    },
    saveForm() {
      this.$refs.accountSettingSocialForm
        .validate()
        .then(success => {
          if (success) {
            this.saving = true
            this.$kpRequest({ ...this.$kpEndpoint.profile.social, payload: this.socialLocal })
              .then(res => {
                this.$emit('update-social', this.socialLocal)
                apiToastSuccess(res.data.message)
                analytics.track(constants.TRACKS.ACTIONS.SAVE_PROFILE_SETTINGS_SOCIAL)
              })
              .catch(err => {
                apiToastWarning(err)
              })
              .finally(() => {
                this.saving = false
              })
          }
        })
        .catch(() => {})
    },
  },
}
</script>
