<template>
  <div>
    <div
      v-if="croppedImg"
      class="cropper-section"
    >
      <Cropper
        ref="cropper"
        class="cropper banner-cropper"
        :src="croppedImg"
        :stencil-props="{
          aspectRatio: 5.3/1.6
        }"
        image-restriction="fit-area"
      />
      <div class="text-center mt-2 mb-1">
        <b-button
          class="mr-1 font-weight-bolder"
          variant="outline-info"
          @click="croppedImg = ''"
        >
          Cancel
        </b-button>
        <b-button
          v-if="croppedImg"
          class="ml-1"
          variant="info"
          :disabled="isCropping"
          @click="crop()"
        >
          {{ isCropping ? "Cropping...." : "Crop" }}
        </b-button>
      </div>
    </div>
    <div>
      <div v-if="hasBanner">
        <label
          v-if="!croppedImg"
          class="drag-and-drop"
          for="uploads"
        >
          <feather-icon
            class="upload-icon"
            icon="UploadCloudIcon"
            size="100"
          />
          <h2><b>Select Image</b></h2>
          <h4>Click to select files from your system</h4>
          <p>
            Upload up to 3 banners here. Allowed JPG, GIF or PNG. Max size of
            5MB each.
          </p>
        </label>
        <input
          id="uploads"
          ref="uploadImg"
          type="file"
          class="input-upload-file"
          accept="image/png, image/jpeg, image/gif, image/jpg"
          multiple
          @change="onSelectImage($event, 1)"
        >
      </div>
    </div>

    <!-- cropped images list -->
    <div v-if="!croppedImg">
      <div
        v-for="(img, index) of profileGeneral.banner"
        :key="index"
        class="cropped-banners-list"
        title="cropped banner"
      >
        <b-row class="cropped-banner">
          <b-col cols="6">
            <div>
              <div>
                <img
                  :src="img"
                  class="banner-image"
                  width="250"
                  height="80"
                >
              </div>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="title-section">
              <p class="h3 font-weight-bolder banner-name">
                banner{{ index + 1 }}.jpeg
              </p>
            </div>
          </b-col>
          <b-col cols="2">
            <div
              class="text-center delete-section"
              @click="$bvModal.show('modal-delete' + index)"
            >
              <feather-icon
                class="delete-icon"
                icon="ArchiveIcon"
                size="25"
              />
              <p>Remove</p>
            </div>
          </b-col>
        </b-row>
        <b-modal
          :id="'modal-delete' + index"
          size="sm"
          modal-class="modal-danger"
          centered
          hide-footer
        >
          <p class="h3 font-weight-bolder text-primary">
            Are you sure want to remove ?
          </p>
          <div class="mt-1 text-center">
            <b-button
              class="mr-1 font-weight-bolder"
              variant="outline-info"
              @click="$bvModal.hide('modal-delete' + index)"
            >Cancel</b-button>
            <b-button
              class="ml-1"
              variant="danger"
              @click="deleteBanner(index)"
            >Delete</b-button>
          </div>
        </b-modal>
      </div>
      <div class="text-center mt-2 mb-1">
        <b-button
          class="mr-1 font-weight-bolder"
          variant="outline-info"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
        <b-button
          class="ml-1 font-weight-bolder"
          variant="info"
          :disabled="isSaveDisabled"
          @click="saveBanners(true)"
        >
          Save
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BModal,
} from 'bootstrap-vue'
import { apiToastWarning, apiToastSuccess } from '@/@core/utils/toast'
import { getLinkWithoutQueryParams, dataURLtoFile, toBase64 } from '@/common-utils'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Compress from 'compress.js'
import { Cropper } from 'vue-advanced-cropper'
import { IMAGE_COMPRESSER } from '@/constants'
import { UPLOAD_DOCUMENTS } from '@/store/modules/common.module'

export default {
  name: 'BannerCropper',
  components: {
    BButton,
    BRow,
    BCol,
    BModal,
    FeatherIcon,
    Cropper,
  },

  props: {
    profileGeneral: {
      type: [Object, Array],
      default() {
        return []
      },
    },
  },
  data() {
    return {
      croppedImg: null,
      isCropping: false,
      uploadedFile: '',
    }
  },
  computed: {
    hasBanner() {
      return this.profileGeneral?.banner?.length < 3
    },
    isSaveDisabled() {
      return !this.profileGeneral?.banner?.length
    },
  },
  emits: ['on-save', 'on-cancel'],
  methods: {
    clearImg() {
      this.croppedImg = ''
    },
    saveBanners() {
      this.$emit('on-save')
    },
    async deleteBanner(index) {
      this.profileGeneral.banner.splice(index, 1)
      const payload = this.profileGeneral
      payload.banner = this.profileGeneral.banner.map(img => getLinkWithoutQueryParams(img))
      this.$kpRequest({ ...this.$kpEndpoint.profile.general, payload })
        .then(() => {
          this.$bvModal.hide(`modal-delete${index}`)
          apiToastSuccess(`banner ${index + 1} removed`)
        })
        .catch(err => {
          apiToastWarning(err)
        })
    },
    // on click crop
    async crop() {
      this.isCropping = true
      const { canvas } = this.$refs.cropper.getResult()
      const logoFile = canvas.toDataURL()
      let file = dataURLtoFile(logoFile, 'banner.png')
      if (file.size / 1024 > 1024) {
        const compress = new Compress()
        const data = await compress.compress([file], IMAGE_COMPRESSER)
        file = dataURLtoFile(data[0].prefix + data[0].data, 'banner.png')
      }
        if (file instanceof File) {
          await this.$store.dispatch(UPLOAD_DOCUMENTS, { fileType: `banner${this.getAvailableBannerIndex()}`, file })
            .then(async res => {
              this.profileGeneral.banner.push(res)
              this.isCropping = false
              this.clearImg()
              apiToastSuccess('Your file(s) are uploaded')
            })
            .catch(err => apiToastWarning(err))
        }
    },
    // on click upload
    async onSelectImage(e, num) {
       this.uploadedFile = e.target.files[0]
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        return false
      }
      if (num === 1) {
        this.croppedImg = await toBase64(this.uploadedFile)
      }
        this.$refs.uploadImg.value = ''
      return 0
    },
    // getting available index from out off 3 s3 urls
    getAvailableBannerIndex() {
      const bannersCount = ['1', '2', '3']
      const bannersIndex = this.profileGeneral.banner.map(banner => banner[banner.indexOf('banner') + 6])
      const missedBanner = bannersCount.find(e => !bannersIndex.includes(e))
      if (missedBanner) {
        return missedBanner
      }
      return null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-advanced-cropper.scss';
</style>
<style lang="scss" scoped>
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";

* div {
  margin: 0;
  padding: 0;
}

#removeLabel {
  display: none;
}

.drag-and-drop {
  width: $banner-section;
  height: $banner-section;
  border-radius: 10px;
  border: 2px dashed $gray-600;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;

  .upload-icon {
    color: $blue;
  }
}

.cropped-banners-list {
  margin-top: 10px;
  .cropped-banner {
    border-radius: $margin-padding;
    border: 1px solid $gray-600;
  }
  .banner-image {
    margin: $margin-padding;
    border-radius: $margin-padding;
  }
  .title-section {
    width: $banner-section;
    height: $banner-section;
    .banner-name {
      margin-top: 30px;
    }
  }

  .delete-section {
    width: $banner-section;
    height: $banner-section;
    color: $kbd-bg;
    background: $light-red;
    border-top-right-radius: $margin-padding;
    border-bottom-right-radius: $margin-padding;

    .delete-icon {
      margin-top: 30px;
    }
  }
  .delete-section:hover {
    background: $red;
  }
}
.input-upload-file {
  position: absolute;
  clip: rect(0 0 0 0);
}

.content {
  margin-bottom: 10%;
}

</style>
