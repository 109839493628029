<template>
  <div>
    <div
      v-for="(addr, index) of addressList"
      :key="index"
      class="container-fluid"
    >
      <!-- header -->
      <div
        v-if="!isPopup"
        class="d-flex"
      >
        <h4 class="mt-1 w-100">
          <feather-icon
            icon="TruckIcon"
            size="18"
          />
          <span class="mb-0 ml-75">
            {{ isTypeShipping ? 'Shipping' : 'Billing' }} Address
            {{ address.length != 1 ? index + 1 : '' }}
          </span>
          <div class="float-right">
            <b-button
              v-if="addr.isPrimary && isTypeShipping && addr._id"
              class="btn btn-secondary text-white disabled"
              size="sm"
              disabled
            >
              Primary
            </b-button>
            <template v-if="!addr.isEditEnabled && isTypeShipping">
              <b-dropdown
                size="sm"
                toggle-class="bg-white border-white"
                variant="light"
                right
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                  />
                </template>
                <template>
                  <b-dropdown-item
                    variant="primary"
                    :disabled="addr.isPrimary"
                    @click="setAsPrimaryAddress(addr._id)"
                  >Set As Primary</b-dropdown-item>
                  <b-dropdown-item
                    variant="primary"
                    @click="addr.isEditEnabled = true"
                  >Edit</b-dropdown-item>
                  <b-dropdown-item
                    variant="danger"
                    :disabled="addr.isPrimary"
                    @click="removeAddress(addr)"
                  >Remove</b-dropdown-item>
                </template>
              </b-dropdown>
            </template>
          </div>
        </h4>
      </div>

      <!-- for popup -->
      <div v-if="isPopup && !addr.isEditEnabled">
        <div class="float-left">
          <b-form-radio-group
            v-model="addr.isPrimary"
            name="shipping-address"
            aria-controls="ex-disabled-readonly"
            @change="setAsPrimaryAddress(addr._id)"
          >
            <b-form-radio value="true" />
          </b-form-radio-group>
        </div>
        <div class="ml-3">
          <address-detail :address="addr" />
          <hr class="my-1">
        </div>
      </div>

      <!-- address form -->
      <div v-else>
        <address-form
          :address="addr"
          :countries="commons.COUNTRIES"
          :add-or-update-address="addOrUpdateAddress"
          @cancel="onClickCancel(addr, index)"
        />
      </div>
    </div>

    <!-- button for add new address -->
    <div
      v-if="canShowAddShippingAddress && !isMultipleCart"
      class="mb-1 text-right"
    >
      <b-button
        :class="{ 'font-weight-bolder': true, 'w-100': isCheckout }"
        variant="primary"
        @click="addNewShippingAddress"
      >
        <div v-if="!isCheckout">
          <feather-icon
            icon="PlusIcon"
            size="15"
            class="mr-0"
          />
          <span>ADD ADDRESS</span>
        </div>
        <div v-else>
          <span>Add a New Shipping Address</span>
        </div>
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BFormRadioGroup,
  BFormRadio,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import AddressForm from './AddressForm.vue'
import AddressDetail from './AddressDetail.vue'

const { ADDRESSES_TYPE } = constants

export default {
  name: 'AddressList',
  components: {
    BButton,
    BFormRadioGroup,
    BFormRadio,
    BDropdown,
    BDropdownItem,
    AddressDetail,
    AddressForm,
  },
  props: {
    addressType: {
      type: String,
      default: '',
      required: true,
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    address: {
      type: Array,
      default() {
        return []
      },
    },
    commons: {
      type: Object,
      default: () => {},
    },
    addOrUpdateAddress: {
      type: Function,
      default: () => [],
    },
    removeAddress: {
      type: Function,
      default: () => [],
    },
    setAsPrimaryAddress: {
      type: Function,
      default: () => [],
    },
    isMultipleCart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addressList: [],
    }
  },
  computed: {
    isTypeShipping() {
      return this.addressType === ADDRESSES_TYPE.SHIPPING
    },
    isTypeBilling() {
      return this.addressType === ADDRESSES_TYPE.BILLING
    },
    isCheckout() {
      return this.$route.name === 'checkout'
    },
    canShowAddShippingAddress() {
      const length = this.addressList.length
      return this.isTypeShipping && !!this.addressList[length - 1]?._id
    },
  },
  watch: {
    address: {
      handler() {
        this.getAddressList()
      },
      deep: true,
    },
  },
  mounted() {
    this.getAddressList()
  },
  methods: {
    getAddressList() {
      this.addressList = JSON.parse(JSON.stringify(this.address))
      if (this.addressList.length < 1) {
        this.addressList.push(this.newAddress(this.addressType, true))
      }
    },
    newAddress(addressType, isPrimary = false) {
      const add = {
        addressLine1: null,
        addressLine2: null,
        city: null,
        country: null,
        contactName: null,
        contactNumber: '',
        contactEmail: null,
        zipcode: null,
        isPrimary,
        addressType,
        isEditEnabled: true,
        isSaving: false,
      }
      return add
    },
    // on click cancel
    onClickCancel(addressData, index) {
      if (this.address.length === 0) {
        // clear form by passing 'null'
        this.resetForm(addressData, null)
      } else if (addressData._id) {
        if (!this.isTypeBilling) {
          addressData.isEditEnabled = false
        }
        this.resetForm(addressData, this.address[index])
      } else {
        this.addressList.pop()
      }
    },
    // reset form
    resetForm(addressData, resetData) {
      if (resetData != null) {
        addressData.addressLine1 = resetData.addressLine1
        addressData.addressLine2 = resetData.addressLine2
        addressData.city = resetData.city
        addressData.country = resetData.country
        addressData.contactName = resetData.contactName
        addressData.contactNumber = resetData.contactNumber
        addressData.contactEmail = resetData.contactEmail
        addressData.zipcode = resetData.zipcode
      } else {
        addressData.addressLine1 = null
        addressData.addressLine2 = null
        addressData.city = null
        addressData.country = null
        addressData.contactName = null
        addressData.contactNumber = ''
        addressData.contactEmail = null
        addressData.zipcode = null
      }
    },
    addNewShippingAddress() {
      this.addressList.push(this.newAddress(ADDRESSES_TYPE.SHIPPING, false))
    },
  },
}
</script>
<style>
.vue-tel-input {
  display: flex !important;
}
</style>
